/* Footer Main Styles */
.footer {
    background-color: #9d9784;
    /* color: #fff; */
    padding: 0px 10px;
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    align-items: center;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px 0;
  }
  
  .footer h4 {
    font-size: 16px;
    color: #e1e1e1;
    margin-bottom: 10px;
  }
  
  .footer p, .footer a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer a:hover {
    color: #c4c4c4;
  }
  
  /* Social Media Icons */
  .social-icons a {
    color: #fff;
    margin: 0 8px;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #c4c4c4;
  }
  
  /* Footer Bottom Section */
  .footer-bottom {
    /* margin-top: 20px; */
    /* border-top: 1px solid #e1e1e1; */
    padding: 10px;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #e1e1e1;
    font-size: 13px;
  }
  
  .footer-link {
    margin: 0 5px;
    color: #fff;
    font-size: 14px;
  }
  
  .footer-link:hover {
    color: #c4c4c4;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  }
  