.mybutton {
    background-color: #e1ddd4; /* Pastel pink */
    color: #9d9784; /* Muted dark grey for text */
    border-radius: 12px;
    font-size: 1rem;
    border: 2px solid #b0a89b; /* Light border */
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    display: grid;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .mybutton:hover {
    background-color: #e1ddd4; /* Slightly lighter pink */
    transform: scale(1.1);
  }
  
  .mybutton:active {
    background-color: #9d9784; /* Slightly darker pink */
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .mybutton:focus {
    background-color: #e1ddd4; /* Slightly lighter pink */
    transform: scale(1.1);
  }
  .selected  {

    color: #9d9784; /* Muted dark grey for text */
    border-radius: 12px;
    font-size: 1rem;
    border: 2px solid #b0a89b; /* Light border */
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    display: grid;
    padding-left: 16px;
    padding-right: 16px;

    background-color: #e1ddd4; /* Slightly lighter pink */
    transform: scale(1.1);
  }
  
  .menuimg {
    width: 80px;
    height: 80px;
    filter: grayscale(100%) brightness(80%);
    /* margin-right: 8px; */
    transition: filter 0.3s ease;
  }
  
  .mybutton:hover .menuimg  {
    filter: grayscale(0%) brightness(100%);
  }

  .mybutton:focus .menuimg  {
    filter: grayscale(0%) brightness(100%);
  }

  .selected_menuimg  {
    width: 80px;
    height: 80px;
    transition: filter 0.3s ease;
    filter: grayscale(0%) brightness(100%);
  }

  .menu {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menutext{
    cursor:pointer;
    /* text-decoration:underline; */
  }